<template>
  <div v-if="isLoading">
    <v-progress-linear indeterminate :color="color" :style="{ width: width }" />
    <p>{{ label }}</p>
  </div>
</template>

<script>
export default {
  name: "LoadingIndicator",
  props: {
    isLoading: { type: Boolean, default: true },
    label: { type: String, default: "Cargando..." },
    color: { type: String, default: "primary" },
    width: { type: String, default: "100%" }
  }
};
</script>

<style scoped>
div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
