<template>
  <v-container>
    <div v-if="role === 'admin'" class="text-center d-flex ma-2">
      <v-btn color="primary" @click="dialog = true">
        <v-icon large>mdi-plus</v-icon> Crear desarrollo
      </v-btn>
      <v-dialog v-model="dialog" width="500px" class="white">
        <real-estate-development-create-form />
      </v-dialog>
    </div>
    <LoadingIndicator :isLoading="loading" class="ma-5" width="80%" />
    <section class="container__developments">
      <v-card
        v-for="(item, i) in realEstateDevelopments"
        :key="item.id"
        class="elevation-4 rounded-xl"
        width="485"
      >
        <v-img
          class="rounded-t-xl"
          :src="
            !item.media.featured_image || item.media.featured_image.src == ''
              ? '/img/image-not-available.png'
              : item.media.featured_image.src
          "
          height="245"
          gradient="to top, rgba(2,2,2,.33), rgba(6,6,6,.6)"
        >
          <v-row align="center" justify="center">
            <div v-if="editAcces">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    class="mx-8 my-7"
                    small
                    v-bind="attrs"
                    v-on="on"
                    :to="{ path: './edit/' + item.code }"
                    ><v-icon color="primary" small>mdi-pencil</v-icon></v-btn
                  >
                </template>
                <span class="white--text">Edición de desarrollo</span>
              </v-tooltip>
            </div>
            <v-spacer></v-spacer>
            <div v-if="item.development_status">
              <v-btn
                :color="getColorStatus(item.development_status.actual_status)"
                class="my-8 mx-7 white--text rounded-lg"
              >
                {{
                  item.development_status.actual_status
                    | tagContractStatusFormat
                }}</v-btn
              >
            </div>
            <div v-else>
              <v-btn color="#BDBDBD" class="my-8 mx-7 white--text rounded-lg">
                Indefinido</v-btn
              >
            </div>
          </v-row>

          <v-row align="center" justify="center">
            <h1 class="text-center white--text text-name-shadow">
              {{ item.name }}
            </h1>
          </v-row>
          <div v-if="quoteAcces">
            <v-row align="center" justify="center" class="">
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="role === 'admin'"
                    fab
                    class="my-8 mx-7"
                    small
                    :loading="loadingFavorite && i == index"
                    @click="buttonChangeFavorite(item, i)"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon color="orange">{{
                      getFavorite(item)
                    }}</v-icon></v-btn
                  >
                  <div v-else>
                    <v-btn
                      v-if="item.favorite"
                      fab
                      class="my-8 mx-7"
                      small
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>{{ getFavorite(item) }}</v-icon></v-btn
                    >
                  </div>
                </template>
                <span class="white--text">Favorito</span>
              </v-tooltip>
              <v-spacer></v-spacer>

              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="activateQuoteTwo(item.miscellaneous.quote)"
                    fab
                    class="my-8 mx-7"
                    small
                    :to="{ path: './quoteAdvanced/' + item.code }"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>mdi-calculator</v-icon></v-btn
                  >
                </template>
                <span class="white--text">Cotizador</span>
              </v-tooltip>

              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="activateQuoteOne(item.miscellaneous.quote)"
                    fab
                    class="my-8 mx-7"
                    small
                    :to="{ path: './quote/' + item.code }"
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon>mdi-calculator</v-icon></v-btn
                  >
                </template>
                <span class="white--text">Cotizador</span>
              </v-tooltip>
            </v-row>
          </div>
        </v-img>
        <v-row align="center" justify="center" class="ma-3">
          <v-col cols="12" md="6">
            <h3
              class="text-center unit--text font-weight-regular"
              v-text="
                !item.development_structure.num_properties ||
                item.development_structure.num_properties === ''
                  ? 'Sin registro'
                  : item.development_structure.num_properties + ' unidades'
              "
            ></h3>
            <div v-if="item.contact_data" class="text-center text-truncate">
              <a
                :href="item.contact_data.web"
                class="
                        price--text
                        font-weight-regular
                        size-text-web
                        text-center
                        text-truncate
                      "
                target="_blank"
                v-text="
                  !item.contact_data ||
                  item.contact_data.web == '' ||
                  item.contact_data === null
                    ? 'Sin información'
                    : item.contact_data.web
                "
              ></a>
            </div>
            <div v-else class="text-center">
              <a
                href="#"
                class="
                        price--text
                        font-weight-regular
                        size-text
                        text-center
                      "
              >
                Sin información</a
              >
            </div>
            <div v-for="(price, i) in item.pricing" :key="i">
              <h4 class="price--text font-weight-regular">
                <div v-if="i === 0">
                  <span
                    >{{ priceFormat(price.general_price_ranging.from) }} a
                    {{ priceFormat(price.general_price_ranging.to) }}
                  </span>
                  {{ price.currency }}
                </div>
              </h4>
            </div>
            <br />
            <v-list dense v-if="item.contact_data">
              <v-list-item class="size-item px-0">
                <v-list-item-icon>
                  <v-icon color="#394154">mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light">{{
                    item.contact_data.phone
                  }}</v-list-item-title>
                  <v-list-item-title
                    v-if="
                      !item.contact_data ||
                        item.contact_data.phone === '' ||
                        item.contact_data.phone == null
                    "
                    class="font-weight-light"
                    >Sin información</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="copytoClipboard(item.contact_data.phone)">
                    <v-icon color="grey lighten-1" x-small
                      >mdi-content-copy</v-icon
                    >
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item class="size-item px-0">
                <v-list-item-icon>
                  <v-icon color="#EDC043">mdi-email-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light">{{
                    item.contact_data.email
                  }}</v-list-item-title>
                  <v-list-item-title
                    v-if="
                      !item.contact_data ||
                        item.contact_data.email === '' ||
                        item.contact_data.email == null
                    "
                    class="font-weight-light"
                    >Sin información</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="copytoClipboard(item.contact_data.email)">
                    <v-icon color="grey lighten-1" x-small
                      >mdi-content-copy</v-icon
                    >
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-list dense v-else>
              <v-list-item
                v-for="item in contact_data"
                :key="item.id"
                class="size-item px-0"
              >
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light"
                    >Sin datos</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" md="6">
            <div class="text-right">
              <v-chip
                color="#4CAF50"
                class="white--text font-weight-bold size-chip"
                :href="`../${item.code}/property/list`"
                >Ir a inventario<v-icon right large>
                  mdi-chevron-right
                </v-icon></v-chip
              >
            </div>
            <v-list dense color="transparent" v-if="item.contact_data">
              <v-list-item class="size-item px-0">
                <v-list-item-avatar size="30" tile>
                  <v-img
                    src="/svg/drive-google.svg"
                    contain
                    height="20px"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light">
                    {{ item.contact_data.drive_folder }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="
                      !item.contact_data ||
                        item.contact_data.drive_folder === '' ||
                        item.contact_data.drive_folder == null
                    "
                    class="font-weight-light"
                  >
                    Sin información
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    @click="copytoClipboard(item.contact_data.drive_folder)"
                  >
                    <v-icon color="grey lighten-1" x-small
                      >mdi-content-copy</v-icon
                    >
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item class="size-item px-0">
                <v-list-item-avatar size="30" tile>
                  <v-img src="/svg/pdf-icon.svg" contain height="20px"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light">
                    {{ item.contact_data.brochure }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="item.contact_data.brochure === ''"
                    class="font-weight-light"
                    >Sin información</v-list-item-title
                  >
                  <v-list-item-title
                    v-if="
                      !item.contact_data ||
                        item.contact_data.brochure === '' ||
                        item.contact_data.brochure == null
                    "
                    class="font-weight-light"
                  >
                    Sin información
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    @click="copytoClipboard(item.contact_data.brochure)"
                  >
                    <v-icon color="grey lighten-1" x-small
                      >mdi-content-copy</v-icon
                    >
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item class="size-item px-0">
                <v-list-item-avatar size="30" tile v-if="item.contact_data">
                  <v-img src="/svg/instagram.svg" contain height="20px"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="item.contact_data.social_networks"
                    class="font-weight-light"
                  >
                    {{ item.contact_data.social_networks.instagram }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="
                      !item.contact_data.social_networks ||
                        item.contact_data.social_networks.instagram === '' ||
                        item.contact_data.social_networks.instagram == null
                    "
                    class="font-weight-light"
                    >Sin información</v-list-item-title
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    @click="
                      copytoClipboard(
                        item.contact_data.social_networks.instagram,
                        true
                      )
                    "
                  >
                    <v-icon
                      :color="
                        clipboardStatus === true ? 'success' : 'grey lighten-1'
                      "
                      x-small
                      v-text="
                        clipboardStatus === true
                          ? 'mdi-check'
                          : 'mdi-content-copy'
                      "
                    ></v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-if="item.contact_data" class="size-item px-0">
                <v-list-item-avatar size="30" tile>
                  <v-img src="/svg/facebook.svg" contain height="35px"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="item.contact_data.social_networks"
                    class="font-weight-light"
                  >
                    {{ item.contact_data.social_networks.facebook }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="
                      !item.contact_data.social_networks ||
                        item.contact_data.social_networks.facebook === '' ||
                        item.contact_data.social_networks.facebook == null
                    "
                    class="font-weight-light"
                    >Sin información</v-list-item-title
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    @click="
                      copytoClipboard(
                        item.contact_data.social_networks.facebook,
                        true
                      )
                    "
                  >
                    <v-icon
                      :color="
                        clipboardStatus === true ? 'success' : 'grey lighten-1'
                      "
                      x-small
                      v-text="
                        clipboardStatus === true
                          ? 'mdi-check'
                          : 'mdi-content-copy'
                      "
                    ></v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-list dense color="transparent" v-else>
              <v-list-item
                v-for="item in social_media"
                :key="item.id"
                class="size-item px-0"
              >
                <v-list-item-avatar size="30" tile>
                  <v-img :src="item.icon" contain height="20px"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light">
                    Sin datos
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-divider class="mx-8"></v-divider>
        <v-card-actions class="">
          <v-list class="ma-0 pa-0">
            <v-list-item class="ma-0 pa-0">
              <v-list-item-content class="ma-0 pa-0 text-center">
                <v-list-item-title
                  class="city-address-text font-weight-regular"
                >
                  {{ getCityAddress(item.address) }}</v-list-item-title
                >
                <v-list-item-subtitle class="full-address-text break-spaces ">
                  {{ item.address?.full_address || "Sin dirección" }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list
            color="#EBEEF9"
            rounded
            class="rounded-lg break-space ma-0 pa-0"
          >
            <v-list-item class="ma-0 pa-0">
              <v-list-item-avatar class="ma-0 pa-0">
                <v-img src="/svg/key-chain.svg" height="30px" contain></v-img>
              </v-list-item-avatar>
              <v-list-item-content class="ma-0 pa-0">
                <v-list-item-title class="date-delivered-text"
                  >Entrega estimada</v-list-item-title
                >
                <v-list-item-subtitle class="text-capitalize">{{
                  item.development_status?.delivery_date | dateFormat(true)
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-actions>
      </v-card>
    </section>
  </v-container>
</template>

<script>
import moment from "moment";
moment.locale("es");
import {
  GetRealEstateDevelopmentList,
  UpdateRealEstateDevelopment
} from "@/api/real-estate-development-api.js";
import RealEstateDevelopmentCreateForm from "./RealEstateDevelopmentCreateForm.vue";
import LoadingIndicator from "../common/LoadingIndicator.vue";
export default {
  components: { RealEstateDevelopmentCreateForm, LoadingIndicator },
  data() {
    return {
      index: -1,
      loadingFavorite: false,
      role: localStorage.getItem("user_role"),
      quoteAcces: false,
      editAcces: false,
      realEstateDevelopments: [],
      image_estate: "",
      searchQuery: null,
      loading: true,
      clipboardStatus: false,
      real_name: "",
      editedIndex: -1,
      searchRealEstate: [],
      show: false,
      social_media: [
        {
          icon: "/svg/drive-google.svg"
        },
        {
          icon: "/svg/pdf-icon.svg"
        },
        {
          icon: "/svg/instagram.svg"
        },
        {
          icon: "/svg/facebook.svg"
        }
      ],
      contact_data: [
        { icon: "mdi-phone", color: "#394154" },
        { icon: "mdi-email-outline", color: "#EDC043" }
      ],
      imgEstate: "",
      pricing: [],
      estatus: "",
      quoteVerification: "",
      status: {},
      dialog: false
    };
  },
  methods: {
    //Mostrar cotizadores..

    activateQuoteOne(quote) {
      if (!quote) return false;
      if (!quote.active) return false;
      if (quote.active == "on" && !this.activateQuoteTwo(quote)) return true;
      return false;
    },
    activateQuoteTwo(quote) {
      if (!quote) return false;
      if (!quote.active_two) return false;
      if (quote.active_two == "on") return true;
      return false;
    },

    getFavorite(item) {
      if (item.favorite) {
        if (item.favorite === true) {
          return "mdi-star";
        } else {
          return "mdi-star-outline";
        }
      } else {
        return "mdi-star-outline";
      }
    },
    buttonChangeFavorite(item, i) {
      let favorite = item.favorite;
      this.loadingFavorite = true;
      this.index = i;
      if (item.favorite) {
        favorite = false;
      } else {
        favorite = true;
      }

      const updateFavorite = {
        favorite: favorite
      };

      this.UpdateDevelopment(
        updateFavorite,
        item,
        "Actualizando la opción de favorito",
        favorite
      );
    },

    UpdateDevelopment(dataUpdate, item, message, favorite) {
      const successMessage = "Actualizado correctamente";
      const errorMessage = "Error";
      let self = this;
      self.loading = true;

      this.$snotify.async(
        message,
        "Procesando ...",
        () =>
          new Promise((resolve, reject) => {
            UpdateRealEstateDevelopment(dataUpdate, item._id)
              .then(() => {
                resolve({
                  title: `${successMessage}`,
                  body: `Proceso ejecutado correctamente`,
                  config: {
                    closeOnClick: true,
                    titleMaxLength: 30,
                    timeout: 4000
                  }
                });
                item.favorite = favorite;
              })
              .catch(err => {
                reject({
                  title: `${errorMessage}!!!`,
                  body: `Ocurrio un problema en el proceso. ${err}`,
                  config: {
                    closeOnClick: true,
                    timeout: 4000
                  }
                });
              })
              .finally(() => {
                self.loading = false;
                this.loadingFavorite = false;
                // self.clearForm();
              });
          })
      );
    },
    checkBrokerAcces() {
      if (this.role === "broker") {
        const moduleQuote = localStorage.getItem("moduleQuote");
        if (moduleQuote) {
          if (moduleQuote === "on") {
            this.editAcces = false;
            this.quoteAcces = true;
          }
        } else {
          this.editAcces = false;
          this.quoteAcces = false;
        }
      }
      if (this.role === "admin") {
        this.editAcces = true;
        this.quoteAcces = true;
      }
      if (this.role === "coordinator") {
        this.editAcces = true;
        this.quoteAcces = false;
      }
    },
    getColorStatus(statusColor) {
      switch (statusColor) {
        case "investment": {
          return "#495369";
        }
        case "construction": {
          return "#FF9800";
        }
        case "finished": {
          return "#34A853";
        }
        case "": {
          return "#757575";
        }
      }
      return "#BDBDBD";
    },
    async consultRealEstateDevelopmentList() {
      GetRealEstateDevelopmentList()
        .then(res => {
          let responseRealEstate = res.RealEstateDevelopment;
          // // dar formato a precios
          responseRealEstate.map(red => {
            red.pricing_usd = { from: 0, to: 0 };
            red.pricing_mxn = { from: 0, to: 0 };
            red.pricing.map(price => {
              if (price.currency === "MXN") {
                red.pricing_mxn = {
                  from: price.general_price_ranging.from,
                  to: price.general_price_ranging.to
                };
              } else if (price.currency === "USD") {
                red.pricing_usd = {
                  from: price.general_price_ranging.from,
                  to: price.general_price_ranging.to
                };
              }
            });
          });
          this.realEstateDevelopments = responseRealEstate;
        })
        .finally(() => (this.loading = false));
    },
    priceFormat(price) {
      if (parseFloat(price) <= 0 || isNaN(price)) {
        return "-";
      }
      return (
        "$ " +
        parseFloat(price)
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .concat(" ")
      );
    },
    copytoClipboard(link, menu) {
      let self = this;
      navigator.clipboard.writeText(link).then(function() {
        self.clipboardStatus = menu;
        self.show = true;
        self.clipboardStatus = false;
      });
    },
    getCityAddress(address) {
      if (address?.city && address?.state) {
        return address.city + ", " + address.state;
      }
      return "Sin información";
    }
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.realEstateDevelopments.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.name.toLowerCase().includes(v));
        });
      } else {
        return this.realEstateDevelopments;
      }
    }
  },
  created() {
    this.consultRealEstateDevelopmentList();
    this.checkBrokerAcces();
  },
  filters: {
    tagContractStatusFormat(status) {
      switch (status) {
        case "investment": {
          return "Inversión";
        }
        case "construction": {
          return "En Obra";
        }
        case "finished": {
          return "Terminado";
        }
        case "": {
          return "Indefinido";
        }
      }
      return "Indefinido";
    },
    dateFormat(date, isFullDate = false) {
      if (!date) return "sin fecha";
      if (!date) return "";
      let format = "MMMM YYYY";
      if (isFullDate) format = "MMMM YYYY";
      let dateParsed = moment(String(date)).format(format);
      return dateParsed;
    }
  }
};
</script>
<style lang="scss" scoped>
.size-chip {
  width: 200px;
  height: 45px;
}
.price--text {
  color: #23626f;
}
.unit--text {
  color: #8e8c8c;
}
.size-text {
  font-size: 15px;
  text-align: center;
}
.size-text-web {
  font-size: 12px;
  text-align: center;
}
.size-item {
  height: 40px;
}
.v-chip.v-size--default {
  border-radius: 11px !important;
  font-size: 14px;
}
.full-address-text {
  font-size: 12px;
  font-weight: 300;
  color: #949eb5;
}
.city-address-text {
  font-size: 13px;
  color: #394154;
}
.date-delivered-text {
  font-size: 13px;
}
.break-spaces {
  white-space: break-spaces;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 82px;
  height: 42px;
}
.break-space {
  white-space: break-spaces;
}
.text-name-shadow {
  text-shadow: 3px 3px #0b0b0b3d;
}
pre code {
  display: block;
  background: none;
  white-space: pre;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  max-width: 100%;
  min-width: 100px;
  padding: 0;
}

.container__developments {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 1rem;
}
</style>
